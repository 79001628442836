import React from "react";

import Layout from "../../components/layout";
import { Seo } from "../../components";

import WithService from "../../service/withService";
// import AboutProfile from "../../components/about-profile";
import AboutProfileList from "../../components/about-profile-list";
import { SearchProfile } from "../../components/search-profiles";
import { useSearchProfile } from "../../hooks/useSearchProfile";

const Clergies = ({ loading, response, error }) => {
  const talonProps = useSearchProfile({
    data: response?.people,
  });

  const { filteredData, filter, data, searchLength } = talonProps;

  return (
    <>
      {data?.length > 0 && (
        <SearchProfile
          onChange={filter}
          actualData={data}
          searchLength={searchLength}
        />
      )}
      <div className="row mb-4">
        <AboutProfileList
          loading={loading}
          response={filteredData}
          isProfileImage={true}
        />
      </div>
    </>
  );
};

const ActiveClergy = ({ location }) => {
  return (
    <Layout location={location}>
      <Seo title="The Clergy in Active Service" />
      <div className="about">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3 className="about-subtitle">The Clergy in Active Service</h3>
              <div className="row">
                <WithService endpoint="people-by-head/people-clergy-active">
                  <Clergies />
                </WithService>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ActiveClergy;
